import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles
} from "@material-ui/core";


const listaEstoque = [
  {
    produto: "Selim de Couro",
    marca: "Shimano",
    fornecedor: "Shimano LTDA",
    categoria: "Acessórios",
    quantidade: 13,
    preco: 53.99
  },
  {
    produto: "Caloi 2000",
    marca: "Caloi",
    fornecedor: "Tião Bikes",
    categoria: "Bicicletas",
    quantidade: 2,
    preco: "599,99"
  },
  {
    produto: "Pedivela",
    marca: "Shimano",
    fornecedor: "Shimano",
    categoria: "Acessórios",
    quantidade: 11,
    preco: "599,99"
  },
  {
    produto: "Rodas R500 20'",
    marca: "Shimano",
    fornecedor: "Shimano",
    categoria: "Acessórios",
    quantidade: 2,
    preco: "1.599,99"
  },
  {
    produto: "Quadro - Tamanho 58'",
    marca: "Shimano",
    fornecedor: "Shimano",
    categoria: "Acessórios",
    quantidade: 2,
    preco: "5.000,00"
  }
];


const folhaDeEstilos = makeStyles({
  grid: {
    padding: "15px"
  },
  tabela: {
    width: "100%"
  },
  media: {
    height: 140,
  },
});

function ListaEstoque() {
  const estilos = folhaDeEstilos();
  return (
    <Grid container className={estilos.grid} spacing={3}>
      {listaEstoque.map((estoque, i) => (
        <Grid item key={i} xs={12}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {estoque.produto}
                </Typography>
                <table className={estilos.tabela}>
                  <tr>
                    <td>
                      <Typography variant="body2">
                        Marca: {estoque.marca}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2">
                        Fornecedor: {estoque.fornecedor}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2">
                        Categoria: {estoque.categoria}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body">
                        Quantidade: {estoque.quantidade}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        Preço: {estoque.preco}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Apagar
              </Button>
              <Button size="small" color="primary">
                Editar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
export default ListaEstoque;
