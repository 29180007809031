import React from "react";
import "./telaPedidos.css";
import { Link, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, Typography } from "@material-ui/core";
import { TelaVenda } from "./venda";
import { TelaEncomenda } from "./encomenda";

function SelecaoPedidos({ match }) {
  //compila folha de estilo style
  const estilo = style();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <div className={estilo.comBorda}>
          <h3>
            <Link to={`${match.path}/encomenda`}>Encomenda</Link>
          </h3>
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className={estilo.comBorda}>
          <h3>
            <Link to={`${match.path}/venda`}>Venda</Link>
          </h3>
        </div>
      </Grid>
    </Grid>
  );
}

const style = makeStyles(theme => ({
  comBorda: {
    border: "2px solid gray",
    padding: "1px",
    backgroundColor: "#fafafa",
    textAlign: "center",
    fontSize: "18pt",
  }
}));

export function TelaPedidos({ match, history }) {
  return (
    <Box m={6}>
      <Typography variant="h3">PEDIDOS</Typography>
      <br />
      <Route exact path={match.path} component={SelecaoPedidos} />
      <Route path={`${match.path}/venda`} component={TelaVenda} />
      <Route path={`${match.path}/encomenda`} component={TelaEncomenda} />
    </Box>
  );
}
