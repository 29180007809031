import React from "react";
import { Fab, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Route, withRouter } from "react-router-dom";
import Add from '@material-ui/icons/Add'
import Novo from './formulario';
import "./estoque.css";
import ListaEstoque from "./listaEstoque";

const style = makeStyles((theme) => ({
    fabTop: {
        position: 'fixed',
        bottom: '40px',
        right: '50px',
    },
    container: {
      maxWidth: "75vw",
      margin: "0 auto"
    }
}));

export const TelaEstoque = withRouter(function ({ match, history }) {
    const compilada = style();
    return (
        <div className={compilada.container}>
            <Typography className="estoque" variant="h3">ESTOQUE</Typography>
            <Route exact path={match.path} component={ListaEstoque}/>
            <Route path={`${match.path}/novo`} component={Novo} />
            <Fab className={compilada.fabTop} color="primary" onClick={() => history.push(`${match.path}/novo`)}>
                <Add />
            </Fab>
        </div>
    );
});