import React from "react";
import "./dashboard.css";
import grafico from "./grafico.png";

export function Dashboard() {
  return (
    <div>
      <div className="graficos">
        <h1>DASHBOARDS</h1>
      </div>
      <div>
        <img alt='Gráfico' src={grafico} className="imagemGrafico"/>
      </div>
     </div>
  );
}
