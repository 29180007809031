import React from "react";
import "./compras.css";
import { Typography, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Add from '@material-ui/icons/Add';
import {withRouter } from "react-router-dom";

const style = makeStyles(theme => ({
    container: {
    maxWidth: "75vw",
    margin: "0 auto"
  }, 
  fabTop: {
    position: 'fixed',
    bottom: '40px',
    right: '50px',
}
}));

export const TelaCompras = withRouter(function ({ match, history }) {
  
    const compilada = style();
  return (
    <div className={compilada.container}>
      <Typography className="compras" variant="h3">
        COMPRAS
      </Typography>
      <Fab className={compilada.fabTop} color="primary" size="large" onClick={() => history.push(`${match.path}/novo`)}>
                <Add />
            </Fab>
    </div>
  );
  });
