import React from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";

const valoresIniciais = {
  nome: "",
  cnpj: "",
  cep: "",
  telefone: "",
  email: "",
  numEndereco: "",
};

const modeloDados = yup.object({
  nome: yup.string().required().min(10),
  cnpj: yup.string().required().min(13),
  cep: yup.string().required().min(9),
  telefone: yup.string().required().min(11),
  email: yup.string().required().min(10),
  numEndereco: yup.string().required().min(1)
})

function enviaFormulario(valores, acoesDoForm) {}

function limpa() {}

// Declara folha de estilos
const estilos = makeStyles({
  // Nome de uma classe
  formulario: {
    // Propriedades CSS
    maxWidth: "40vw",
    margin: "auto"
  }
});

function CamposForm(touched, errors) {
  // Compila folha de estilos
  const estilosComp = estilos();
  return (
    <Form>
      <Grid container spacing={3} className={estilosComp.formulario}>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            helperText = {touched.nome && errors.nome}
            type="text"
            name="nome"
            label="Nome"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="cpfcnpj"
            label="CPF/CNPJ"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Esse é o campo fornecedor referencia o valor inicial fornecedor pelo name */}
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="cep"
            label="CEP"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="numEndereco"
            label="Nº Endereço"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="telefone"
            label="Telefone"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} md={12}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="email"
            label="E-mail"
            variant="outlined"
          />
        </Grid>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            variant="contained"
            color="secondary"
            onClick={enviaFormulario}
            type="submit"
          >
            Cadastrar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={limpa}
            type="button"
          >
            Limpar
          </Button>
        </Box>
      </Grid>
    </Form>
  );
}

export default function Formulario() {
  // Documentação Formik
  return (
    <div>
      <Formik
        initialValues={valoresIniciais}
        component={CamposForm}
        validationSchema={modeloDados}
        onSubmit={enviaFormulario}
      ></Formik>
    </div>
  );
}
