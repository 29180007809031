import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
  Grid
} from "@material-ui/core";

const listaOS = [
  {
    codigo: "01",
    dt_inicial: "07/09/2019",
    dt_final: "07/09/2019",
    tipo: "Corretiva",
    cod_pedido: 13,
    pecas: "Selim",
    valor: 15.0,
    funcionario: "Fulano",
    obs: "Troca do selim com defeito"
  },
  {
    codigo: "02",
    dt_inicial: "11/09/2019",
    dt_final: "11/09/2019",
    tipo: "Preventiva",
    cod_pedido: 19,
    pecas: "Rodas Shimano",
    valor: 499.99,
    funcionario: "Fulano",
    obs: "Troca de rodas defeituosas para rodas Shimano"
  }
];

const folhaDeEstilos = makeStyles({
  tabela: {
    width: "100%",
    lineHeight: "20px"
  },
  alto: {
    height: "100%"
  }
});

function ListaOS() {
  const estilos = folhaDeEstilos();
  return (
    <Grid container spacing={3}>
      {listaOS.map((os, i) => (
        <Grid item xs={12}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {os.codigo}
                </Typography>
                <table className={estilos.tabela}>
                  <tr>
                    <td>
                      <Typography variant="body2">
                        Data Inicial: {os.dt_inicial}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2">
                        Data Final: {os.dt_final}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body2">
                        Tipo de Serviço: {os.tipo}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body">
                        Código Pedido: {os.cod_pedido}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">Peças: {os.pecas}</Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        Valor R$: {os.valor}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body">
                        Funcionário: {os.funcionario}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        Observação: {os.obs}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Apagar
              </Button>
              <Button size="small" color="primary">
                Editar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
export default ListaOS;
