import React, { useState, useEffect } from "react";
import { fromFetch } from "rxjs/fetch";
import { flatMap } from "rxjs/operators";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles
} from "@material-ui/core";

const folhaDeEstilos = makeStyles({
  grid: {
    padding: "15px"
  },
  tabela: {
    width: "100%"
  }
});

function ListaCliente() {
  const estilos = folhaDeEstilos();
  const [listaClientes, setListaClientes] = useState([]);
  useEffect(() => {
    const subscription = fromFetch(
      process.env.REACT_APP_BACKEND_URL + "/clientes",
      {
        headers: {
          Accepts: "application/json"
        }
      }
    )
      .pipe(flatMap(res => res.json()))
      .subscribe(res => {
        setListaClientes(res._items);
      });
    return subscription.unsubscribe.bind(subscription);
  }, []);

  return (
    <Grid container className={estilos.grid} spacing={3}>
      {listaClientes.map((cliente, i) => (
        <Grid item key={i} xs={12}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {cliente.nome}
                </Typography>
                <table className={estilos.tabela}>
                  <tbody>
                    <tr>
                      <td>
                        <Typography variant="body1">
                          CPF: {cliente.cpf}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1">
                          Telefone: {cliente.telefone}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1">
                          E-mail: {cliente.email}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body1">
                          CEP: {cliente.cep}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1">
                          Logradouro: {cliente.logradouro}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1">
                          Nº Endereço: {cliente.numEndereco}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Apagar
              </Button>
              <Button size="small" color="primary">
                Editar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
export default ListaCliente;
