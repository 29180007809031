import React from "react";
import "./fornecedores.css";
import { Fab, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Route, withRouter } from "react-router-dom";
import Add from '@material-ui/icons/Add'
import Novo from './formularioFornecedor';
import "./listaFornecedores";
import ListaFornecedor from "./listaFornecedores";


const style = makeStyles((theme) => ({
    fabTop: {
        position: 'fixed',
        bottom: '40px',
        right: '50px',
    },
    container: {
      maxWidth: "75vw",
      margin: "0 auto"
    }
}));

export const TelaFornecedores = withRouter(function ({match, history}){
    
    const compilada = style();
    return(
        <div className={compilada.container}>
            <Typography className="fornecedores" variant="h3">FORNECEDORES</Typography>
            <Route exact path={match.path} component = {ListaFornecedor}/>
            <Route path={`${match.path}/novo`} component={Novo} />
            <Fab className={compilada.fabTop} color="primary" onClick={() => history.push(`${match.path}/novo`)}>
            <Add />
            </Fab>
        </div>
    );
});