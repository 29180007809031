import "./barraTitulo.css";
import React from "react";
export function BarraTitulo() {
  return (
    <div className="barrinha">
      <div>SISTEMA</div>
      <div>ADMIN</div>    
    </div>
  );
}
