import React from "react";
import "./App.css";
import tema from "./theme";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { Dashboard } from "./dashboard";
import { BarraTitulo } from "./barraTitulo";
import { TelaPedidos } from "./pedidos";
import { TelaClientes } from "./clientes";
import { TelaFornecedores } from "./fornecedores";
import { TelaCompras } from "./compras";
import { TelaOS } from "./os";
import { TelaEstoque } from "./estoque";
import {TelaServicos} from "./serviços";

import Person from "@material-ui/icons/Person";
import Home from "@material-ui/icons/Home";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Store from "@material-ui/icons/Store";
import Build from "@material-ui/icons/Build";
import Assignment from "@material-ui/icons/Assignment";
import ExitToApp from "@material-ui/icons/ExitToApp";

function App() {
  return (
    <ThemeProvider theme={tema}>
      <Router>
        <div className="App">
          <div className="sidebar">
            <div>
              <h1>
                <span className="byte">BYTE</span>
                <span className="cycle">CYCLE</span>
              </h1>
              <h3>
                <Link to="/">
                  <Home />
                  Home
                </Link>
              </h3>
              <h3>
                <Link to="/clientes">
                  <Person />
                  Clientes
                </Link>
              </h3>
              <h3>
                <Link to="/compras">
                  <ShoppingCart />
                  Compras
                </Link>
              </h3>
              <h3>
                <Link to="/estoque">
                  <Store />
                  Estoque
                </Link>
              </h3>
              <h3>
                <Link to="/fornecedores">
                  <LocalShipping />
                  Fornecedores
                </Link>
              </h3>
              <h3>
                <Link to="/os">
                  <Build />
                  Ordem de Serviço
                </Link>
              </h3>
              <h3>
                <Link to="/pedidos">
                  <Assignment />
                  Pedidos
                </Link>
              </h3>
              <h3>
                <Link to="/servicos">
                  <Build />
                  Serviços
                </Link>
              </h3>
            </div>
            <h3>
              <Link to="#"><ExitToApp/>Sair</Link>
            </h3>
          </div>
          <div className="content">
            <BarraTitulo />
            <div>
              <Route path="/" exact component={Dashboard} />
              <Route path="/pedidos" component={TelaPedidos} />
              <Route path="/clientes" component={TelaClientes} />
              <Route path="/fornecedores" component={TelaFornecedores} />
              <Route path="/compras" component={TelaCompras} />
              <Route path="/os" component={TelaOS} />
              <Route path="/estoque" component={TelaEstoque} />
              <Route path="/servicos" component={TelaServicos}/>
            </div>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
