import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles,
  Grid
} from "@material-ui/core";

const listaServicos = [
    {
      cod: "01",
      servico: "Troca do banco",
      valor: "5.00",
      descricao: "Troca do selim da bike.",
      
    }
  ];

  const folhaDeEstilos = makeStyles({
    tabela: {
      width: "100%",
      lineHeight: "20px"
    },
    alto: {
      height: "100%"
    }
  });
  
  function ListaServicos() {
    const estilos = folhaDeEstilos();
    return (
      <Grid container spacing={3}>
        {listaServicos.map((serv, i) => (
          <Grid item xs={12}>
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {serv.cod}
                  </Typography>
                  <table className={estilos.tabela}>
                    <tr>
                      <td>
                        <Typography variant="body2">
                          Serviço: {serv.servico}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body2">
                          Valor: {serv.valor}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body2">
                          Descrição: {serv.descricao}
                        </Typography>
                      </td>
                    </tr>                   
                  </table>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  Apagar
                </Button>
                <Button size="small" color="primary">
                  Editar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
  export default ListaServicos;
  