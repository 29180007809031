import React from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui'
import { makeStyles } from '@material-ui/styles';
import * as yup from "yup";

// Valores iniciais dos campos do form, referencia os fields pelo 'name'
const valoresIniciais = {
    produto: '',
    marca: '',
    fornecedor: 'Shimano',
    categoria: '',
    quantidade: 0,
    preco: 0.0,
}

const modeloDados = yup.object({

    produto: yup.string().required().min(10),
    marca: yup.string().required().min(10),
    fornecedor: yup.string().required().min(10),
    categoria: yup.string().required().min(6),
    quantidade: yup.string().required().min(1),
    preco: yup.string().required().min(1)

})

function enviaFormulario(valores, acoesDoForm) {
}

function limpa() {


}

// Declara folha de estilos
const estilos = makeStyles({
    // Nome de uma classe
    formulario: {
        // Propriedades CSS
        maxWidth: '40vw',
        margin: 'auto',
    },
});

function CamposForm(touched, errors) {
    // Compila folha de estilos
    const estilosComp = estilos();
    return (
        <Form>
            <Grid container spacing={3} className={estilosComp.formulario}>
                <Grid item xs={12}>
                    <Field fullWidth component={TextField} helperText={touched.produto && errors.produto} type="text" name="produto" label="Produto" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Field fullWidth component={TextField} type="text" name="marca" label="Marca" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    {/* Esse é o campo fornecedor referencia o valor inicial fornecedor pelo name */}
                    <Field fullWidth component={TextField} type="text" name="fornecedor" label="Fornecedor" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <Field fullWidth component={TextField} type="text" name="categoria" label="Categoria" variant="outlined" />
                </Grid>
                <Grid item xs={6} md={12}>
                    <Field fullWidth component={TextField} type="text" name="quantidade" label="Quantidade" variant="outlined" />
                </Grid>
                <Grid item xs={6} md={12}>
                    <Field fullWidth component={TextField} type="text" name="preco" label="Preço" variant="outlined" />
                </Grid>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button variant="contained" color="secondary" onClick={enviaFormulario} type='submit'>Cadastrar</Button>
                    <Button variant="contained" color="secondary" onClick={limpa} type='button'>Limpar</Button>
                </Box>
            </Grid>
        </Form>
    );
}

export default function Formulario() {
    // Documentação Formik
    return (
        <div>
            <Formik initialValues={valoresIniciais} component={CamposForm} onSubmit={enviaFormulario} validationSchema={modeloDados}>
            </Formik>
        </div>
    );
}
