import React from 'react';
import "./serviços.css";
import { Fab, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Route, withRouter } from "react-router-dom";
import Add from '@material-ui/icons/Add'
import Novo from './formularioServicos';
import ListaServicos from './listaServiços';

const style = makeStyles((theme) => ({
    fabTop: {
        position: 'fixed',
        bottom: '40px',
        right: '50px',
    },
    container: {
        maxWidth: "75vw",
        margin: "0 auto"
    }
}));

export const TelaServicos = withRouter(function ({ match, history }) {
    const compilada = style();
    return (
        <div className={compilada.container}>        
            <Typography className="serviços" variant="h3">SERVIÇOS</Typography>    
            <Route exact path={match.path} component={ListaServicos} />
            <Route path={`${match.path}/novo`} component={Novo} />
            <Fab className={compilada.fabTop} color="primary" size="large" onClick={() => history.push(`${match.path}/novo`)}>
                <Add />
            </Fab>
        </div>
    );
});