import React from "react";
import { Box, Typography } from "@material-ui/core";
import "./encomenda.css";

function PedidoEncomenda() {
  return (
    <div>
      
    </div>
  );
}

export const TelaEncomenda = function() {
  return (
    <Box m={6}>
      <Typography variant="h6">ENCOMENDA</Typography>
      <PedidoEncomenda></PedidoEncomenda>
    </Box>
  );
};
