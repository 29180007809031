import { createMuiTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import blueGrey from '@material-ui/core/colors/blueGrey';

export default createMuiTheme({
  palette: {
    primary: cyan,
    secondary: blueGrey,
  },
});
