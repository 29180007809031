import React from "react";
import { Box, Typography } from "@material-ui/core";

function PedidoVenda() {
  return <div></div>;
}

export function TelaVenda() {
  return (
    <Box m={6}>
      <Typography variant="h6">VENDA</Typography>
      <PedidoVenda></PedidoVenda>
    </Box>
  );
}
