import React from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";

// Valores iniciais dos campos do form, referencia os fields pelo 'name'
const valoresIniciais = {
  servico: "Troca de banco",
  valor: 20.00,
  descricao: "Este serviço vai trocar o banco da bicicleta",
};

const modeloDados = yup.object({
  servico: yup.string().required().min(5),
  valor: yup.string().required().min(1),
  descricao: yup.string().required().min(10)
})

function enviaFormulario(valores, acoesDoForm) {}

function limpa() {}

// Declara folha de estilos
const estilos = makeStyles({
  // Nome de uma classe
  formulario: {
    // Propriedades CSS
    maxWidth: "40vw",
    margin: "auto"
  }
});

function CamposForm(touched, errors) {
  // Compila folha de estilos
  const estilosComp = estilos();
  return (
    <Form>
      <Grid container spacing={3} className={estilosComp.formulario}>
        <Grid item xs={6}>
          <Field
            fullWidth
            component={TextField}
            helperText = {touched.servico && errors.servico}
            type="text"
            name="servico"
            label="Serviço"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="valor"
            label="R$ Valor"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          {/* Aqui deveria ser um combobox */}
          <Field fullWidth component={TextField} type="text"
            name="descricao"
            label="Descrição do Serviço"
            variant="outlined"
          />
        </Grid>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Button variant="contained" color="secondary" onClick={enviaFormulario} type="submit">Cadastrar</Button>
          <Button variant="contained" color="secondary" onClick={limpa} type="button" >Limpar</Button>
        </Box>
      </Grid>
    </Form>
  );
}

export default function Formulario() {
  // Documentação Formik
  return (
    <div>
      <Formik
        initialValues={valoresIniciais}
        component={CamposForm}
        validationSchema = {modeloDados}
        onSubmit={enviaFormulario}
      ></Formik>
    </div>
  );
}
