import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  makeStyles
} from "@material-ui/core";

const listaFornecedor = [
  {
    nome: "Shimano LTDA",
    cnpj: "03.193.191/00001-99",
    cep: "02564-090",
    endereco: "Rua da Shimano",
    numEndereco: 13,
    telefone: "5511999999999",
    email: "comercial@shimano.com"
  },
  {
    nome: "Caloi",
    cnpj: "27.932.734/0001-65",
    cep: "06696-110",
    endereco: "Rod. Coronel-Policia Militar Nelson Tranchesi",
    numEndereco: 1730,
    telefone: "(11)2388-0493",
    email: "comercial@caloi.com"
  },
  {
    nome: "JR Distribuidora de Peças e Acessórios para Bicicletas",
    cnpj: "27.932.734/0001-00",
    cep: "00000-110",
    endereco: "Av. das Industrias",
    numEndereco: 1062,
    telefone: "(51)3374-0033",
    email: "contato@jrcomercio.com"
  }
];

const folhaDeEstilos = makeStyles({
  grid: {
    padding: "25px"
  },
  tabela: {
    width: "100%"
  },
  media: {
    height: 190
  },
  logo: {
    height: 1
  }
});

function ListaFornecedor() {
  const estilos = folhaDeEstilos();

  return (
    <Grid container className={estilos.grid} spacing={2} cols={3}>
      {listaFornecedor.map((fornecedor, i) => (
        <Grid item key={i}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {fornecedor.nome}
                </Typography>
                <table className={estilos.tabela}>
                  <tbody>
                    <tr>
                      <td>
                        <Typography variant="body2">
                          CNPJ: {fornecedor.cnpj}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body2">
                          CEP: {fornecedor.cep}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body2">
                          Endereço: {fornecedor.endereco}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body2">
                          Nº: {fornecedor.numEndereco}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body2">
                          Telefone: {fornecedor.telefone}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body2">
                          E-mail: {fornecedor.email}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Apagar
              </Button>
              <Button size="small" color="primary">
                Editar
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
export default ListaFornecedor;
