import React from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";

// Valores iniciais dos campos do form, referencia os fields pelo 'name'
const valoresIniciais = {
  responsavel: "",
  tipoServico: "",
  pedido: 0,
  valor: 0.0,
  pecas: "",
  anotacoes: "",
  dtInicio: "",
  dtFim: "",
  numOS: 0
};

const modeloDados = yup.object({
responsavel: yup.string().required().min(10),
tipoServico: yup.string().required().min(10),
pedido: yup.string().required().min(10),
valor: yup.string().required().min(1),
pecas: yup.string().required().min(5),
anotacoes: yup.string().required().min(1),
dtInicio: yup.string().required().min(8),
dtFim: yup.string().required().min(8),
numOS: yup.string().required().min(2),

})

function enviaFormulario(valores, acoesDoForm) {}

function limpa() {}

// Declara folha de estilos
const estilos = makeStyles({
  // Nome de uma classe
  formulario: {
    // Propriedades CSS
    maxWidth: "40vw",
    margin: "auto"
  }
});

function CamposForm(touched, errors) {
  // Compila folha de estilos
  const estilosComp = estilos();
  return (
    <Form>
      <Grid container spacing={3} className={estilosComp.formulario}>
        <Grid item xs={4}>
          <Field
            fullWidth
            component={TextField}
            helperText = {touched.numOS && errors.numOS}
            type="text"
            name="numOS"
            label="Código OS"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="dtInicio"
            label="Data Inicial"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="dtFim"
            label="Data Final"
            variant="outlined"
          />
        </Grid>

        {/* Aqui deve ser um combobox --> só deixei TextField pra exemplificar */}
        <Grid item xs={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="tipoServico"
            label="Tipo de Serviço"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="pedido"
            label="Código Pedido"
            variant="outlined"
          />
        </Grid>

        {/*Aqui deveria ser um modal que abria para selecionar os produtos do estoque*/}
        <Grid item xs={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="pecas"
            label="Peças"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="valor"
            label="Valor"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="responsavel"
            label="Funcionário Responsável"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="anotacoes"
            label="Observações"
            variant="outlined"
          />
        </Grid>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Button
            variant="contained"
            color="secondary"
            onClick={enviaFormulario}
            type="submit"
          >
            Cadastrar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={limpa}
            type="button"
          >
            Limpar
          </Button>
        </Box>
      </Grid>
    </Form>
  );
}

export default function Formulario() {
  // Documentação Formik
  return (
    <div>
      <Formik
        initialValues={valoresIniciais}
        validationSchema = {modeloDados}
        component={CamposForm}
        onSubmit={enviaFormulario}
      ></Formik>
    </div>
  );
}
