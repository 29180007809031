import React from "react";
import "./clientes.css";
import { Fab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Route, withRouter } from "react-router-dom";
import Add from "@material-ui/icons/Add";
import Novo from "./formularioCliente";
import ListaCliente from "./listaClientes";


const style = makeStyles({
  fabTop: {
    position: "fixed",
    bottom: "40px",
    right: "50px"
  },
  container: {
    maxWidth: "75vw",
    margin: "0 auto"
  }
});

export const TelaClientes = withRouter(function({ match, history }) {
  const compilada = style();
  return (
    <div className={compilada.container}>
      <Typography className="clientes"  variant="h3">CLIENTES</Typography>
      <Route exact path={match.path} component={ListaCliente} />
      <Route path={`${match.path}/novo`} component={Novo} />
      <Fab
        className={compilada.fabTop}
        color="primary"
        size="large"
        onClick={() => history.push(`${match.path}/novo`)}
      >
        
        <Add />
      </Fab>
    </div>
  );
});
